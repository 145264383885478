body {
  margin: 0;
  padding: 0;
  height: 100%;
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 10vw;
}

.App {
  display: flex;
  font-family: sans-serif;
  align-items: center;
  justify-content: center;
  background: rgb(209, 5, 5);
  height: 100vh;
}

#title {
  translate: 0 -100% ;
  animation: flickering .1s infinite;
  opacity: .6;
}

@keyframes flickering {
  0% {
    color: black
  }
  0% {
    color: rgb(255, 132, 0)
  }
  100% {
    color: black
  }
}